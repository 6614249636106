/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AttachmentItemsFilters.vue?vue&type=template&id=476b6b47"
import script from "./AttachmentItemsFilters.vue?vue&type=script&lang=ts&setup=true"
export * from "./AttachmentItemsFilters.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionSubTitle: require('/code/src/components/text/SectionSubTitle.vue').default,Input: require('/code/src/components/input/Input.vue').default,DateInput: require('/code/src/components/input/DateInput.vue').default})
