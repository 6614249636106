var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SimpleDialog, {
    ref: "dialogRef",
    attrs: {
      "title": _setup.$t('action.additionPrintedMatter'),
      "actions": {
        false: _setup.$t('action.cancel'),
        true: _setup.$t('ofInSelection', [_setup.attachments.length]) + _setup.$t('action.doAdd')
      },
      "valid": !!_setup.attachments.length,
      "width": "100%",
      "clazz": "tw-max-w-[1200px]",
      "persistent": ""
    },
    on: {
      "click": _setup.onClick,
      "close": function close($event) {
        return _setup.emits('close');
      }
    }
  }, [_c(_setup.AttachmentItems, {
    ref: "attachmentItemsRef",
    attrs: {
      "value": _setup.attachments,
      "type": _setup.type,
      "exclude-items": _vm.value,
      "editable": "",
      "selectable": ""
    },
    on: {
      "update:value": function updateValue($event) {
        _setup.attachments = $event;
      },
      "update:type": function updateType($event) {
        _setup.type = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };