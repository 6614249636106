import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _setup$product, _setup$productCustoms, _setup$productCustoms2, _setup$company, _setup$company2, _setup$product$displa, _setup$product2, _setup$selectedVarian, _setup$product$displa2, _setup$product3, _setup$selectedVarian2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c('Loading', {
    attrs: {
      "value": _setup.processing,
      "modal": ""
    }
  }), _vm._v(" "), _c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v("\n        " + _vm._s(_setup.isCreateMode ? _setup.$t('action.creationTouch') : _setup.$t('action.editingTouch')) + "\n      ")])])], 1), _vm._v(" "), _c('ContentArea', {
    attrs: {
      "set": _vm.labelNumber = 1
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4"
  }, [_vm._v("\n      " + _vm._s(_setup.$t('productName')) + "\n    ")]), _vm._v(" "), _c('ProductSummary', {
    attrs: {
      "item": _setup.product,
      "dummy": !_setup.product
    }
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _setup.data.name,
      "initial-value": _setup.initialData.name,
      "disabled": _setup.isCreateMode
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_setup.data, "name", $event);
      }
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    }
  }, [_vm._v("\n        " + _vm._s(_setup.$t('touchNameForManage')) + "\n        "), _c('RequiredIcon', {
    staticClass: "ml-0.5"
  })], 1), _vm._v(" "), _c('Input', {
    attrs: {
      "placeholder": _setup.$t('touchName'),
      "maxlength": "255",
      "required": ""
    },
    model: {
      value: _setup.data.name,
      callback: function callback($$v) {
        _vm.$set(_setup.data, "name", $$v);
      },
      expression: "data.name"
    }
  })], 1), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _setup.data.variantId,
      "initial-value": _setup.initialData.variantId,
      "disabled": _setup.isCreateMode
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_setup.data, "variantId", $event);
      }
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    }
  }, [_vm._v("\n        " + _vm._s(_setup.$t('touches.selectVariants')) + "\n        "), _c('RequiredIcon', {
    staticClass: "ml-0.5"
  })], 1), _vm._v(" "), _c('TouchVariants', {
    attrs: {
      "variants": (_setup$product = _setup.product) === null || _setup$product === void 0 ? void 0 : _setup$product.variants,
      "mandatory": "",
      "selectable": ""
    },
    model: {
      value: _setup.data.variantId,
      callback: function callback($$v) {
        _vm.$set(_setup.data, "variantId", $$v);
      },
      expression: "data.variantId"
    }
  })], 1), _vm._v(" "), ((_setup$productCustoms = _setup.productCustoms.texts) === null || _setup$productCustoms === void 0 ? void 0 : _setup$productCustoms.length) > 0 || _setup.productCustoms.url ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _setup.data.customTexts,
      "initial-value": _setup.initialData.customTexts,
      "disabled": _setup.isCreateMode
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_setup.data, "customTexts", $event);
      }
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    }
  }, [_vm._v("\n          " + _vm._s(_setup.$t('shipping.customizePrintedText')) + "\n        ")]), _vm._v(" "), ((_setup$productCustoms2 = _setup.productCustoms.texts) === null || _setup$productCustoms2 === void 0 ? void 0 : _setup$productCustoms2.length) > 0 ? _c('TouchTextViewer', {
    attrs: {
      "svg": _setup.productCustoms.template,
      "qr-url": "https://smapo.co",
      "logo": (_setup$company = _setup.company) === null || _setup$company === void 0 ? void 0 : _setup$company.image,
      "customs-texts": _setup.productCustoms.texts,
      "edit": "",
      "use-svg-default-text": ""
    },
    on: {
      "update:error": function updateError($event) {
        _setup.hasTextError = $event;
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$refs.textmenu.show($event);
      }
    },
    model: {
      value: _setup.customTexts,
      callback: function callback($$v) {
        _setup.customTexts = $$v;
      },
      expression: "customTexts"
    }
  }) : _vm._e()], 1), _vm._v(" "), _setup.productCustoms.url ? _c('Input', {
    staticClass: "mt-6",
    attrs: {
      "initial-value": _setup.isCreateMode ? undefined : _setup.initialData.url,
      "label": _setup.$t('qrUrl'),
      "rules": [function (v) {
        return _setup.$isValidUrlOrEmpty(v) || _setup.$t('error.url');
      }],
      "placeholder": _setup.$t('placeholder.urlHint')
    },
    model: {
      value: _setup.data.url,
      callback: function callback($$v) {
        _vm.$set(_setup.data, "url", $$v);
      },
      expression: "data.url"
    }
  }) : _vm._e()] : _vm._e(), _vm._v(" "), _setup.enableAttachments ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _setup.data.attachments,
      "initial-value": _setup.initialData.attachments,
      "disabled": _setup.isCreateMode
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_setup.data, "attachments", $event);
      }
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('button', {
          staticClass: "tw-btn tw-btn-text-primary",
          attrs: {
            "type": "button"
          },
          on: {
            "click": _vm.$openAboutPrinting
          }
        }, [_c('mdi-help-circle', {
          staticClass: "tw-size-4"
        }), _vm._v("\n              " + _vm._s(_setup.$t('aboutPrinting')) + "\n            ")], 1)];
      },
      proxy: true
    }], null, false, 2525006195)
  }, [_vm._v("\n          " + _vm._s(_setup.$t('action.additionPrintedMatter')) + "\n          ")]), _vm._v(" "), _c('AttachmentArea', {
    attrs: {
      "attachments": _setup.data.attachments,
      "editable": ""
    },
    on: {
      "update:attachments": function updateAttachments($event) {
        return _vm.$set(_setup.data, "attachments", $event);
      }
    }
  })], 1)] : _vm._e(), _vm._v(" "), _setup.hasGiftVariant ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('FormHighlight', {
    attrs: {
      "value": _setup.data.lp,
      "initial-value": _setup.initialData.lp,
      "disabled": _setup.isCreateMode
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_setup.data, "lp", $event);
      }
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    }
  }, [_vm._v("\n          " + _vm._s(_setup.$t('shipping.customizeGiftPage')) + "\n        ")]), _vm._v(" "), _c('GiftPageEditor', {
    attrs: {
      "edit": "",
      "logo": (_setup$company2 = _setup.company) === null || _setup$company2 === void 0 ? void 0 : _setup$company2.image,
      "product-title": (_setup$product$displa = (_setup$product2 = _setup.product) === null || _setup$product2 === void 0 ? void 0 : _setup$product2.display_gift_name) !== null && _setup$product$displa !== void 0 ? _setup$product$displa : (_setup$selectedVarian = _setup.selectedVariant) === null || _setup$selectedVarian === void 0 ? void 0 : _setup$selectedVarian.title,
      "product-image": (_setup$product$displa2 = (_setup$product3 = _setup.product) === null || _setup$product3 === void 0 ? void 0 : _setup$product3.display_gift_image) !== null && _setup$product$displa2 !== void 0 ? _setup$product$displa2 : (_setup$selectedVarian2 = _setup.selectedVariant) === null || _setup$selectedVarian2 === void 0 ? void 0 : _setup$selectedVarian2.image
    },
    on: {
      "update:error": function updateError($event) {
        _setup.hasGiftError = $event;
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$refs.textmenu.show($event);
      }
    },
    model: {
      value: _setup.data.lp,
      callback: function callback($$v) {
        _vm.$set(_setup.data, "lp", $$v);
      },
      expression: "data.lp"
    }
  })], 1)] : _vm._e()], 2), _vm._v(" "), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button",
      "disabled": !_setup.isSubmitEnable
    },
    domProps: {
      "textContent": _vm._s(_setup.isCreateMode ? _setup.$t('action.doCreateTouch') : _setup.$t('action.doSave'))
    },
    on: {
      "click": _setup.submit
    }
  })]), _vm._v(" "), _c('TextEditorContextMenu', {
    ref: "textmenu"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };