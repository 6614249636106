/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AttachmentItems.vue?vue&type=template&id=0a06aaa8&scoped=true"
import script from "./AttachmentItems.vue?vue&type=script&lang=ts&setup=true"
export * from "./AttachmentItems.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./AttachmentItems.vue?vue&type=style&index=0&id=0a06aaa8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a06aaa8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/code/src/components/Loading.vue').default,FilePicker: require('/code/src/components/input/FilePicker.vue').default,AttachmentItemsFilters: require('/code/src/components/attachment/AttachmentItemsFilters.vue').default,AttachmentDummyCard: require('/code/src/components/widgets/AttachmentDummyCard.vue').default,AttachmentCard: require('/code/src/components/widgets/AttachmentCard.vue').default,TableTypeSelect: require('/code/src/components/table/TableTypeSelect.vue').default,CustomVDataFooter: require('/code/src/components/table/CustomVDataFooter.vue').default,CoverImage: require('/code/src/components/img/CoverImage.vue').default,UnderSubmissionIcon: require('/code/src/components/icons/UnderSubmissionIcon.vue').default,CustomizableHeaderTable: require('/code/src/components/table/CustomizableHeaderTable.vue').default,ContextMenu: require('/code/src/components/menu/ContextMenu.vue').default,FileDropOverlay: require('/code/src/components/attachment/FileDropOverlay.vue').default,FileUploadOverlay: require('/code/src/components/attachment/FileUploadOverlay.vue').default,FileDropArea: require('/code/src/components/input/FileDropArea.vue').default})
