import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VItemGroup, {
    attrs: {
      "value": _vm.selectedIndex,
      "mandatory": _vm.mandatory
    },
    on: {
      "change": _vm.onSelectChanged
    }
  }, [_c('div', {
    staticClass: "items-wrapper"
  }, [!_vm.variants ? _vm._l(2, function (n) {
    return _c('div', {
      key: n,
      staticClass: "item"
    }, [_c('ProductDummyCard')], 1);
  }) : _vm._l(_vm.variants, function (variant) {
    return _c('div', {
      key: variant.id,
      staticClass: "item"
    }, [_c(VItem, {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var active = _ref.active,
            toggle = _ref.toggle;
          return [_c('ProductVariantCard', {
            attrs: {
              "variant": variant,
              "active": active,
              "selectable": _vm.selectable
            },
            on: {
              "click": toggle
            }
          })];
        }
      }], null, true)
    })], 1);
  }), _vm._v(" "), _vm._l(10, function (n) {
    return _c('div', {
      key: "spacer-".concat(n),
      staticClass: "item item--spacer"
    });
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };