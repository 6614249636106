import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(_setup.SimpleDialog, {
    ref: "dialogRef",
    attrs: {
      "title": _setup.$t('confirmation'),
      "width": "90%",
      "actions": {
        false: _setup.$t('action.cancel'),
        true: _setup.$t('action.doUpload')
      }
    },
    on: {
      "click": function click($event) {
        return _setup.emits('click', $event);
      },
      "close": function close($event) {
        return _setup.emits('close');
      }
    }
  }, [_c('div', {
    staticClass: "tw-px-6 tw-py-2"
  }, [_vm._v("\n    " + _vm._s(_setup.$t('confirm.uploadSameNameFile', [_vm.file.name])) + "\n  ")]), _vm._v(" "), _c('OutlinedTable', {
    staticClass: "tw-mx-6 tw-my-2",
    attrs: {
      "headers": [{
        text: 'ファイル名',
        value: 'file_name',
        multiLine: true,
        ellipsis: false,
        hideable: false
      }, {
        text: 'ファイルサイズ',
        value: 'file_size',
        ellipsis: false
      }, {
        text: '作成者',
        value: 'created_by.name'
      }, {
        text: 'アップロード日時',
        value: 'created_at',
        transform: _vm.$datetime
      }, _vm.$device.isDesktop ? {
        text: '',
        value: 'preview',
        align: 'center',
        hideable: false,
        width: 1
      } : undefined].filter(function (item) {
        return item != null;
      }),
      "items": _vm.attachments,
      "items-per-page": -1,
      "footer-props": {
        'items-per-page-options': [-1]
      },
      "item-key": "id",
      "disable-sort": true,
      "hide-default-footer": "",
      "fixed-header": ""
    },
    scopedSlots: _vm._u([{
      key: "item.file_name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "tw-flex tw-items-center tw-min-w-[100px] tw-whitespace-normal"
        }, [_c('CoverImage', {
          staticClass: "!tw-size-[72px] tw-flex-shrink-0 tw-rounded tw-border tw-border-border tw-my-1 tw-mr-2",
          attrs: {
            "src": item.image
          }
        }), _vm._v(" "), _c('div', {
          directives: [{
            name: "ellipsis",
            rawName: "v-ellipsis.flexible",
            value: 3,
            expression: "3",
            modifiers: {
              "flexible": true
            }
          }]
        }, [!item.is_valid ? _c('UnderSubmissionIcon', {
          staticClass: "tw-mr-2 tw-align-sub"
        }) : _vm._e(), _vm._v("\n          " + _vm._s(item.file_name) + "\n        ")], 1)], 1)];
      }
    }, {
      key: "item.file_size",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v("\n      " + _vm._s(function () {
          var byte = item.file_size;
          var kb = byte / 1000;
          var mb = kb / 1000;
          if (mb > 1) return "".concat(mb.toFixed(1), " MB");else if (kb > 1) return "".concat(kb.toFixed(1), " KB");else return "".concat(byte.toFixed(1), " Byte");
        }()) + "\n    ")];
      }
    }, {
      key: "item.preview",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('button', {
          staticClass: "tw-btn tw-btn-icon-primary tw-btn-icon-x-small",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _setup.preview(item);
            }
          }
        }, [_c('mdi-file-search-outline')], 1)];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };