export default {
  props: {
    variant: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selected: function selected() {
      return this.active && this.selectable;
    }
  }
};