var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tw-rounded-lg tw-border tw-border-border"
  }, [_c('div', {
    staticClass: "tw-rounded-t-lg tw-aspect-[1/1] tw-w-full tw-max-h-[160px]"
  }, [_c('SkeletonLoader')], 1), _vm._v(" "), _c('div', {
    staticClass: "tw-text-body-2 tw-my-2 tw-mx-3"
  }, [_c('SkeletonLoader', {
    attrs: {
      "text": "",
      "line": "2"
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };