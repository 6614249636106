import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCard, {
    class: {
      selected: _vm.selected,
      selectable: _vm.selectable
    },
    attrs: {
      "elevation": "0",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit("click", _vm.variant);
      }
    }
  }, [_c('CoverImage', {
    attrs: {
      "src": _vm.variant.image
    }
  }), _vm._v(" "), _vm.selectable ? _c('TwCheckbox', {
    staticClass: "tw-absolute tw-top-2 tw-right-2 tw-pointer-events-none",
    attrs: {
      "value": _vm.selected,
      "circle": "",
      "cushion": ""
    }
  }) : _vm._e(), _vm._v(" "), _c(VCardTitle, {
    staticClass: "pb-0"
  }, [_c('span', {
    directives: [{
      name: "ellipsis",
      rawName: "v-ellipsis",
      value: 2,
      expression: "2"
    }]
  }, [_vm._v(_vm._s(_vm.variant.title))])]), _vm._v(" "), _c(VCardText, {
    staticClass: "primary-text--text text-right text-subtitle-2 pt-0"
  }, [_vm._v("\n    " + _vm._s(_vm.$price(_vm.variant.price)) + "\n  ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };