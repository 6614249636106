import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('FilePicker', {
    class: _defineProperty(_defineProperty({}, _vm.draggingClass, _setup.dragging), '[&>*]:tw-cursor-no-drop', _setup.dragging && _vm.disabled),
    attrs: {
      "accept": _vm.accept,
      "multiple": _vm.multiple
    },
    on: {
      "file": function file($event) {
        return _setup.emits('file', $event);
      },
      "files": function files($event) {
        return _setup.emits('files', $event);
      },
      "dragover": function dragover($event) {
        $event.preventDefault();
        !_vm.disabled && (_setup.dragging = true);
      },
      "dragleave": function dragleave($event) {
        $event.preventDefault();
        !_vm.disabled && (_setup.dragging = false);
      },
      "drop": function drop($event) {
        $event.preventDefault();
        return _setup.onDrop.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm._t("default", null, null, {
          on: on,
          dragging: _setup.dragging
        })];
      }
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };