import "core-js/modules/es.array.find-index.js";
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    variants: {
      type: [Array],
      default: null
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      selectedItemId: this.value
    };
  },
  computed: {
    selectedIndex: function selectedIndex() {
      var _this$variants,
        _this = this;
      return (_this$variants = this.variants) === null || _this$variants === void 0 ? void 0 : _this$variants.findIndex(function (variant) {
        return variant.id === _this.selectedItemId;
      });
    }
  },
  watch: {
    value: function value(val) {
      this.selectedItemId = val;
    }
  },
  methods: {
    onSelectChanged: function onSelectChanged(index) {
      var _this$variants2, _this$variants2$index;
      this.selectedItemId = (_this$variants2 = this.variants) === null || _this$variants2 === void 0 ? void 0 : (_this$variants2$index = _this$variants2[index]) === null || _this$variants2$index === void 0 ? void 0 : _this$variants2$index.id;
      this.$emit('change', this.selectedItemId);
    }
  }
};