import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('FileDropArea', {
    staticClass: "tw-relative tw-pt-3",
    attrs: {
      "accept": "application/pdf",
      "disabled": !_vm.editable
    },
    on: {
      "file": _setup.select
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var dragging = _ref.dragging;
        return [_vm.attachments.length ? _c('div', {
          staticClass: "tw-flex tw-flex-wrap tw-justify-between -tw-m-2.5"
        }, [_vm._l(_vm.attachments, function (attachment) {
          return _c('AttachmentCard', {
            key: attachment.id,
            staticClass: "tw-grow tw-w-[100px] xs:tw-w-[160px] tw-m-2.5",
            attrs: {
              "attachment": attachment,
              "editable": _vm.editable,
              "height": "100%"
            },
            on: {
              "click:delete": function clickDelete($event) {
                _setup.emits('update:attachments', _vm.attachments.filter(function (item) {
                  return item.id !== attachment.id;
                }));
              },
              "click:preview": function clickPreview($event) {
                return _setup.preview(attachment);
              }
            }
          });
        }), _vm._v(" "), _vm.editable ? _c('button', {
          staticClass: "tw-grow tw-w-[100px] xs:tw-w-[160px] tw-m-2.5 tw-p-2 tw-btn tw-btn-outline-dotted tw-min-h-[200px] tw-flex-col",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _setup.select();
            }
          }
        }, [_c('mdi-plus-circle', {
          staticClass: "tw-mr-2 tw-size-6 tw-shrink-0"
        }), _vm._v("\n        " + _vm._s(_setup.$t('action.doAddPrintedMatter')) + "\n      ")], 1) : _vm._e(), _vm._v(" "), _vm._l(10, function (n) {
          return _c('div', {
            key: "spacer-".concat(n),
            staticClass: "tw-grow tw-w-[100px] xs:tw-w-[160px] tw-mx-2.5 tw-my-0 tw-h-0"
          });
        })], 2) : _vm.editable ? _c('button', {
          staticClass: "tw-btn tw-btn-outline-dotted tw-w-full tw-h-[200px] tw-flex-col",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _setup.select();
            }
          }
        }, [_c('mdi-plus-circle', {
          staticClass: "tw-mr-2 tw-size-6"
        }), _vm._v("\n      " + _vm._s(_setup.$t('action.doAddPrintedMatter')) + "\n    ")], 1) : _vm._e(), _vm._v(" "), _c(_setup.FileDropOverlay, {
          attrs: {
            "visible": dragging && _vm.editable
          }
        })];
      }
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };