/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AttachmentArea.vue?vue&type=template&id=6b84e833"
import script from "./AttachmentArea.vue?vue&type=script&lang=ts&setup=true"
export * from "./AttachmentArea.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AttachmentCard: require('/code/src/components/widgets/AttachmentCard.vue').default,FileDropOverlay: require('/code/src/components/attachment/FileDropOverlay.vue').default,FileDropArea: require('/code/src/components/input/FileDropArea.vue').default})
