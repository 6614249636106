var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tw-absolute tw-inset-0 tw-rounded-lg tw-border-2 tw-border-primary tw-flex tw-flex-col tw-justify-center tw-items-center tw-pointer-events-none tw-bg-surface !tw-bg-opacity-80 tw-opacity-0 tw-transition-opacity",
    class: {
      'tw-opacity-100': _vm.visible
    }
  }, [_c('div', {
    staticClass: "tw-relative tw-w-[200px] tw-h-2 tw-mb-4 tw-overflow-hidden"
  }, [_c('div', {
    staticClass: "tw-absolute tw-rounded-full tw-inset-0 tw-bg-gray-200"
  }), _vm._v(" "), _c('div', {
    staticClass: "tw-absolute tw-rounded-full tw-top-0 tw-bottom-0 tw-left-0 tw-bg-primary tw-transition-[width] tw-duration-[50ms]",
    style: "width: ".concat(_vm.progress, "%")
  })]), _vm._v(" "), _c('div', {
    staticClass: "tw-text-subtitle-1 tw-text-secondary"
  }, [_vm._v("\n    " + _vm._s(_vm.$t('attachments.uploading')) + "\n  ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };