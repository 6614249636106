var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tw-relative",
    on: {
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _setup.emits('contextmenu', $event);
      }
    }
  }, [_c('div', {
    staticClass: "tw-relative tw-rounded-lg tw-border tw-border-border tw-group tw-after-content tw-bg-surface after:tw-rounded-lg after:tw-border-2 after:tw-border-primary after:-tw-inset-[1px] after:tw-transition-opacity after:tw-opacity-0",
    class: {
      'tw-cursor-pointer tw-click-effect before:tw-rounded-lg': _vm.selectable,
      'after:tw-opacity-100': _setup.props.value
    },
    on: {
      "click": function click($event) {
        if (_vm.selectable) {
          _setup.emits('update:value', !_setup.props.value);
        } else {
          _setup.emits('click', _setup.props.attachment, $event);
        }
      }
    }
  }, [_c('div', {
    staticClass: "tw-relative"
  }, [_c('CoverImage', {
    staticClass: "tw-rounded-t-lg",
    attrs: {
      "src": _vm.attachment.image
    }
  }), _vm._v(" "), !_vm.attachment.is_valid ? _c('UnderSubmissionIcon', {
    staticClass: "tw-absolute tw-left-2 tw-bottom-2"
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    directives: [{
      name: "ellipsis",
      rawName: "v-ellipsis",
      value: 2,
      expression: "2"
    }],
    staticClass: "tw-text-body-2 tw-my-2 tw-mx-3 tw-h-[2em]",
    class: {
      'tw-pr-5': _vm.selectable && _vm.$device.isDesktop
    }
  }, [_vm._v("\n      " + _vm._s(_vm.attachment.file_name) + "\n    ")]), _vm._v(" "), _vm.selectable ? _c('TwCheckbox', {
    staticClass: "tw-absolute tw-top-2 tw-right-2 tw-pointer-events-none",
    attrs: {
      "value": _setup.props.value,
      "cushion": ""
    }
  }) : _vm._e(), _vm._v(" "), !_vm.selectable && _vm.$device.isDesktop ? _c('div', {
    staticClass: "tw-rounded-lg tw-opacity-0 tw-transition-opacity group-hover:tw-opacity-100 tw-absolute tw-inset-0 tw-size-full tw-bg-black !tw-bg-opacity-40"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-outline-primary tw-btn-small tw-absolute tw-size-fit tw-bottom-2 tw-left-0 tw-right-0 tw-m-auto",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click:preview', _vm.attachment);
      }
    }
  }, [_c('mdi-file-search-outline'), _vm._v("\n        " + _vm._s(_vm.$t('preview')) + "\n      ")], 1)]) : _vm._e()], 1), _vm._v(" "), _vm.selectable && _vm.$device.isDesktop ? _c('button', {
    staticClass: "tw-btn tw-btn-icon-primary tw-btn-icon-x-small tw-absolute tw-right-2 tw-bottom-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click:preview', _vm.attachment);
      }
    }
  }, [_c('mdi-file-search-outline')], 1) : _vm._e(), _vm._v(" "), _vm.editable ? _c('button', {
    staticClass: "tw-btn tw-btn-icon-primary tw-btn-icon-small !tw-bg-white tw-border-2 tw-border-solid !tw-border-primary tw-absolute -tw-right-3 -tw-top-3",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click:delete', _vm.attachment);
      }
    }
  }, [_c('mdi-close', {
    staticClass: "tw-size-6 tw-m-0.5"
  })], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };