var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tw-absolute tw-inset-0 tw-rounded-lg tw-border-2 tw-border-primary tw-flex tw-flex-col tw-justify-center tw-items-center tw-pointer-events-none tw-bg-surface !tw-bg-opacity-80 tw-opacity-0 tw-transition-opacity",
    class: {
      'tw-opacity-100': _vm.visible
    }
  }, [_c('mdi-file-upload-outline', {
    staticClass: "tw-mb-4 tw-size-20 tw-text-primary"
  }), _vm._v(" "), _c('div', {
    staticClass: "tw-text-subtitle-1 tw-text-primary"
  }, [_vm._v("\n    " + _vm._s(_vm.$t('attachments.dropForUpload')) + "\n  ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };