/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./new.vue?vue&type=template&id=b76ce40e"
import script from "./new.vue?vue&type=script&lang=ts&setup=true"
export * from "./new.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/code/src/components/Loading.vue').default,SectionSubTitle: require('/code/src/components/text/SectionSubTitle.vue').default,ProductSummary: require('/code/src/components/widgets/ProductSummary.vue').default,RequiredIcon: require('/code/src/components/icons/RequiredIcon.vue').default,Input: require('/code/src/components/input/Input.vue').default,FormHighlight: require('/code/src/components/FormHighlight.vue').default,TouchVariants: require('/code/src/components/widgets/TouchVariants.vue').default,TouchTextViewer: require('/code/src/components/widgets/TouchTextViewer.vue').default,AttachmentArea: require('/code/src/components/attachment/AttachmentArea.vue').default,GiftPageEditor: require('/code/src/components/widgets/GiftPageEditor.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default,TextEditorContextMenu: require('/code/src/components/menu/TextEditorContextMenu.vue').default})
