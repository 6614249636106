import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('FileDropArea', {
    staticClass: "tw-relative tw-h-full tw-overflow-y-hidden tw-px-6 tw-py-2 tw-min-h-[240px]",
    attrs: {
      "accept": "application/pdf",
      "disabled": !_vm.editable
    },
    on: {
      "file": _setup.upload
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var on = _ref.on,
          dragging = _ref.dragging;
        return [_c('Loading', {
          attrs: {
            "value": _setup.processing,
            "modal": ""
          }
        }), _vm._v(" "), !_setup.loading && !_setup.attachments.length && _setup.isFilterEmpty ? _c('div', {
          staticClass: "tw-flex tw-size-full tw-flex-col tw-border tw-rounded-lg !tw-border-dashed tw-items-center tw-justify-center"
        }, [_c('mdi-plus', {
          staticClass: "tw-mr-2 tw-size-6 tw-text-info"
        }), _vm._v(" "), _c('span', {
          staticClass: "tw-text-info tw-text-body-1 tw-mb-4"
        }, [_vm._v("\n        " + _vm._s(_setup.$t('attachments.dropHere')) + "\n      ")]), _vm._v(" "), _c('button', _vm._g({
          staticClass: "tw-btn tw-btn-fill-primary",
          attrs: {
            "type": "button"
          },
          domProps: {
            "textContent": _vm._s(_setup.$t('attachments.orSelect'))
          }
        }, on))], 1) : [_c(_setup.AttachmentItemsFilters, {
          staticClass: "tw-mb-2",
          attrs: {
            "filters": _setup.filterOptions
          },
          on: {
            "update:filters": function updateFilters($event) {
              _setup.filterOptions = $event;
            }
          },
          scopedSlots: _vm._u([_vm.editable ? {
            key: "append",
            fn: function fn() {
              return [_c('FilePicker', {
                attrs: {
                  "accept": "application/pdf"
                },
                on: {
                  "file": _setup.upload
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref2) {
                    var on = _ref2.on;
                    return [_c('button', _vm._g({
                      staticClass: "tw-btn tw-btn-text-primary tw-mb-2 tw-whitespace-nowrap",
                      attrs: {
                        "type": "button"
                      }
                    }, on), [_c('mdi-plus-circle', {
                      staticClass: "tw-mr-1 tw-size-6 tw-shrink-0"
                    }), _vm._v("\n                " + _vm._s(_setup.$t('action.upload')) + "\n              ")], 1)];
                  }
                }], null, true)
              })];
            },
            proxy: true
          } : null], null, true)
        }), _vm._v(" "), _setup.type === 'grid' ? _c('div', {
          staticClass: "tw-relative"
        }, [_c(VProgressLinear, {
          attrs: {
            "absolute": "",
            "indeterminate": "",
            "active": _setup.loading
          }
        }), _vm._v(" "), _c(VDataIterator, {
          attrs: {
            "value": _setup.selectedItems,
            "items": _setup.attachments,
            "item-key": "id",
            "loading": _setup.loading,
            "options": _setup.tableOptions,
            "server-items-length": _setup.totalItems,
            "hide-default-footer": ""
          },
          on: {
            "update:options": function updateOptions($event) {
              _setup.tableOptions = $event;
            },
            "input": function input($event) {
              return _setup.emits('update:value', $event);
            },
            "update:page": function updatePage($event) {
              _vm.$el.getBoundingClientRect().top < 0 && _vm.$el.scrollIntoView({
                behavior: 'smooth'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "no-data",
            fn: function fn() {
              return [_c('div', {
                staticClass: "tw-flex tw-items-center tw-justify-center tw-text-body-1 tw-text-secondary tw-min-h-[214px]"
              }, [_vm._v("\n              " + _vm._s(_setup.$t('error.notFound.filteredFile')) + "\n            ")])];
            },
            proxy: true
          }, {
            key: "loading",
            fn: function fn() {
              return [_c('div', {
                staticClass: "tw-flex tw-flex-wrap tw-justify-between -tw-m-2"
              }, [_vm._l(4, function (n) {
                return _c('AttachmentDummyCard', {
                  key: n,
                  staticClass: "tw-grow tw-w-[100px] xs:tw-w-[160px] tw-m-2"
                });
              }), _vm._v(" "), _vm._l(10, function (n) {
                return _c('div', {
                  key: "spacer-".concat(n),
                  staticClass: "tw-grow tw-w-[100px] xs:tw-w-[160px] tw-mx-2 tw-my-0 tw-h-0"
                });
              })], 2)];
            },
            proxy: true
          }, {
            key: "default",
            fn: function fn(_ref3) {
              var items = _ref3.items,
                isSelected = _ref3.isSelected,
                select = _ref3.select;
              return [_c('div', {
                staticClass: "tw-flex tw-flex-wrap tw-justify-between -tw-m-2 tw-max-h-[calc(90vh_-_160px)] tw-overflow-y-auto"
              }, [_vm._l(items, function (item) {
                return _c('div', {
                  key: item.id,
                  staticClass: "tw-grow tw-w-[100px] xs:tw-w-[160px] tw-m-2"
                }, [_c('AttachmentCard', {
                  attrs: {
                    "attachment": item,
                    "value": isSelected(item),
                    "selectable": _vm.selectable
                  },
                  on: {
                    "update:value": function updateValue($event) {
                      return select(item, $event);
                    },
                    "click": function click(item, event) {
                      _setup.emits('click', item, event);
                    },
                    "click:preview": function clickPreview($event) {
                      return _setup.preview(item);
                    },
                    "contextmenu": function contextmenu($event) {
                      $event.preventDefault();
                      return _setup.showMenu($event, item);
                    }
                  }
                })], 1);
              }), _vm._v(" "), _vm._l(10, function (n) {
                return _c('div', {
                  key: "spacer-".concat(n),
                  staticClass: "tw-grow tw-w-[100px] xs:tw-w-[160px] tw-mx-2 tw-my-0 tw-h-0"
                });
              })], 2)];
            }
          }, {
            key: "footer",
            fn: function fn(_ref4) {
              var pagination = _ref4.pagination,
                opt = _ref4.options,
                updateOptions = _ref4.updateOptions;
              return [_c('CustomVDataFooter', {
                attrs: {
                  "pagination": pagination,
                  "options": opt,
                  "items-per-page-options": [10, 25, 50, 100]
                },
                on: {
                  "update:options": updateOptions
                },
                scopedSlots: _vm._u([{
                  key: "prepend",
                  fn: function fn() {
                    return [_c(VFadeTransition, [!!_setup.selectedItems.length ? _c('button', {
                      staticClass: "tw-btn tw-btn-text-primary",
                      attrs: {
                        "type": "button"
                      },
                      on: {
                        "click": function click($event) {
                          return _setup.emits('update:value', []);
                        }
                      }
                    }, [_c('mdi-trash-can-outline', {
                      staticClass: "tw-size-6"
                    }), _vm._v("\n                    " + _vm._s(_setup.$t('action.doClearSelected')) + "\n                  ")], 1) : _vm._e()]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('TableTypeSelect', {
                      staticClass: "tw-my-3 tw-mr-6 tw-flex-[0_0_0%]",
                      attrs: {
                        "type": _setup.type
                      },
                      on: {
                        "update:type": function updateType($event) {
                          return _vm.$emit('update:type', $event);
                        }
                      }
                    })];
                  },
                  proxy: true
                }], null, true)
              })];
            }
          }], null, true)
        })], 1) : _c('CustomizableHeaderTable', {
          staticClass: "tw-h-full",
          attrs: {
            "value": _setup.selectedItems,
            "headers": [{
              text: 'ファイル名',
              value: 'file_name',
              multiLine: true,
              ellipsis: false,
              hideable: false
            }, {
              text: 'ファイルサイズ',
              value: 'file_size',
              ellipsis: false
            }, {
              text: '作成者',
              value: 'created_by.name'
            }, {
              text: 'アップロード日時',
              value: 'created_at',
              transform: _vm.$datetime
            }, {
              text: 'タッチ添付数',
              value: 'touch_count',
              align: 'center',
              transform: function transform(val) {
                return _setup.$t('case', [val.toLocaleString()]);
              }
            }, _vm.$device.isDesktop ? {
              text: '',
              value: 'preview',
              align: 'center',
              hideable: false,
              width: 1
            } : undefined].filter(function (item) {
              return item != null;
            }),
            "default-headers": ['ファイル名', 'ファイルサイズ', '作成者', 'アップロード日時', ''],
            "items": _setup.attachments,
            "item-key": "id",
            "loading": _setup.loading,
            "options": _setup.tableOptions,
            "server-items-length": _setup.totalItems,
            "disable-sort": true,
            "checkbox-color": "primary",
            "show-select": _vm.selectable,
            "fixed-header": ""
          },
          on: {
            "update:options": function updateOptions($event) {
              _setup.tableOptions = $event;
            },
            "input": function input($event) {
              return _setup.emits('update:value', $event);
            },
            "click:row": function clickRow(item, row, event) {
              if (_vm.selectable) row.select(!row.isSelected);else _setup.emits('click', item, event);
            },
            "auxclick:row": function auxclickRow(event, row) {
              return !_vm.selectable && _setup.emits('click', row.item, event);
            },
            "contextmenu:row": function contextmenuRow(event, row) {
              return _setup.showMenu(event, row.item);
            }
          },
          scopedSlots: _vm._u([{
            key: "header.data-table-select",
            fn: function fn() {
              return undefined;
            },
            proxy: true
          }, {
            key: "no-data",
            fn: function fn() {
              return [_c('div', {
                staticClass: "tw-flex tw-items-center tw-justify-center tw-text-body-1 tw-text-secondary tw-min-h-24"
              }, [_vm._v("\n            " + _vm._s(_setup.$t('error.notFound.filteredFile')) + "\n          ")])];
            },
            proxy: true
          }, {
            key: "loading",
            fn: function fn() {
              return [_c('div', {
                staticClass: "tw-flex tw-items-center tw-justify-center tw-text-body-1 tw-text-secondary tw-min-h-24"
              }, [_vm._v("\n            " + _vm._s(_setup.$t('loading')) + "\n          ")])];
            },
            proxy: true
          }, {
            key: "item.file_name",
            fn: function fn(_ref5) {
              var item = _ref5.item;
              return [_c('div', {
                staticClass: "tw-flex tw-items-center tw-min-w-[100px] tw-whitespace-normal"
              }, [_c('CoverImage', {
                staticClass: "!tw-size-[72px] tw-flex-shrink-0 tw-rounded tw-border tw-border-border tw-my-1 tw-mr-2",
                attrs: {
                  "src": item.image
                }
              }), _vm._v(" "), _c('div', {
                directives: [{
                  name: "ellipsis",
                  rawName: "v-ellipsis.flexible",
                  value: 3,
                  expression: "3",
                  modifiers: {
                    "flexible": true
                  }
                }]
              }, [!item.is_valid ? _c('UnderSubmissionIcon', {
                staticClass: "tw-mr-2 tw-align-sub"
              }) : _vm._e(), _vm._v("\n              " + _vm._s(item.file_name) + "\n            ")], 1)], 1)];
            }
          }, {
            key: "item.file_size",
            fn: function fn(_ref6) {
              var item = _ref6.item;
              return [_vm._v("\n          " + _vm._s(function () {
                var byte = item.file_size;
                var kb = byte / 1000;
                var mb = kb / 1000;
                if (mb > 1) return "".concat(mb.toFixed(1), " MB");else if (kb > 1) return "".concat(kb.toFixed(1), " KB");else return "".concat(byte.toFixed(1), " Byte");
              }()) + "\n        ")];
            }
          }, {
            key: "item.preview",
            fn: function fn(_ref7) {
              var item = _ref7.item;
              return [_c('button', {
                staticClass: "tw-btn tw-btn-icon-primary tw-btn-icon-x-small",
                attrs: {
                  "type": "button"
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _setup.preview(item);
                  }
                }
              }, [_c('mdi-file-search-outline')], 1)];
            }
          }, {
            key: "footer.prepend",
            fn: function fn() {
              return [_c(VFadeTransition, [!!_setup.selectedItems.length ? _c('button', {
                staticClass: "tw-btn tw-btn-text-primary",
                attrs: {
                  "type": "button"
                },
                on: {
                  "click": function click($event) {
                    return _setup.emits('update:value', []);
                  }
                }
              }, [_c('mdi-trash-can-outline', {
                staticClass: "tw-size-6"
              }), _vm._v("\n              " + _vm._s(_setup.$t('action.doClearSelected')) + "\n            ")], 1) : _vm._e()]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('TableTypeSelect', {
                staticClass: "tw-my-3 tw-mr-6 tw-flex-[0_0_0%]",
                attrs: {
                  "type": _setup.type
                },
                on: {
                  "update:type": function updateType($event) {
                    return _vm.$emit('update:type', $event);
                  }
                }
              })];
            },
            proxy: true
          }], null, true)
        })], _vm._v(" "), _c(_setup.ContextMenu, {
          ref: "menuRef"
        }), _vm._v(" "), _c(_setup.FileDropOverlay, {
          staticClass: "tw-mx-6 tw-my-2 tw-z-10",
          attrs: {
            "visible": dragging && _vm.editable
          }
        }), _vm._v(" "), _c(_setup.FileUploadOverlay, {
          staticClass: "tw-mx-6 tw-my-2 tw-z-10",
          attrs: {
            "visible": _setup.uploading,
            "progress": _setup.uploadProgress
          }
        })];
      }
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };